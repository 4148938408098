var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap x-x" },
    [
      _c(
        "div",
        { staticClass: "left" },
        [
          _c(
            "cardTitleCom",
            { staticClass: "trpso", attrs: { cardTitle: "社群码预览" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c("div", { staticStyle: { transform: "scale(0.8)" } }, [
                  _c("img", {
                    staticStyle: { width: "375px", height: "70px" },
                    attrs: {
                      src: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2025-02-07/c9f68dd225e8428c942d1643c884eb25.png",
                    },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "leftBigImg",
                      style: {
                        backgroundImage:
                          "url(" +
                          (_vm.form.commonImageResp.imageUrl ||
                            "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2025-02-13/e30769d2be7f420186e30b171fdc9622.png") +
                          ")",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticStyle: { "padding-top": "147.46px" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "x-f shop",
                              style: {
                                opacity: _vm.form.caseType == 2 ? 1 : 0,
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "city fontBold x-f",
                                  staticStyle: { "margin-right": "10px" },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { "margin-right": "10px" } },
                                    [_vm._v("深圳市")]
                                  ),
                                  _c("div", [
                                    _c("i", {
                                      staticClass: "el-icon-arrow-down",
                                      staticStyle: { "font-size": "12px" },
                                    }),
                                  ]),
                                ]
                              ),
                              _c("div", { staticClass: "omit1 shopName" }, [
                                _vm._v(" 翻身路3号店 "),
                              ]),
                            ]
                          ),
                          _c("div", { staticClass: "x-center" }, [
                            _c("img", {
                              staticStyle: { width: "195px", height: "195px" },
                              attrs: {
                                src: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2025-01-20/16cba8ba7b11455790c2d845c87555f8.png",
                              },
                            }),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "flex1" },
        [
          _c(
            "div",
            { staticClass: "navSearch" },
            [
              _c(
                "cardTitleCom",
                { staticClass: "navCard", attrs: { cardTitle: "社群码管理" } },
                [
                  _c(
                    "template",
                    { slot: "cardContent" },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "form",
                          attrs: { model: _vm.form, "label-width": "120px" },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "启动状态", prop: "brandNo" } },
                            [
                              _c("el-switch", {
                                on: { change: _vm.submitForm },
                                model: {
                                  value: _vm.form.isUseCase,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "isUseCase", $$v)
                                  },
                                  expression: "form.isUseCase",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "活动模式", prop: "brandNo" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  attrs: {
                                    disabled:
                                      _vm.options.list.length > 0 ||
                                      !_vm.form.isUseCase,
                                  },
                                  on: { change: _vm.submitForm },
                                  model: {
                                    value: _vm.form.caseType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "caseType", $$v)
                                    },
                                    expression: "form.caseType",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: 1 } }, [
                                    _vm._v("统一设置"),
                                  ]),
                                  _c("el-radio", { attrs: { label: 2 } }, [
                                    _vm._v("按门店"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "活动展示图片", prop: "brandNo" },
                            },
                            [
                              _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "choosedImageItem x-fc",
                                    on: {
                                      click: function ($event) {
                                        return _vm.getOpenImg()
                                      },
                                    },
                                  },
                                  [
                                    !_vm.form.commonImageResp.imageUrl
                                      ? _c("i", {
                                          staticClass: "el-icon-plus",
                                          staticStyle: { "font-size": "32px" },
                                        })
                                      : _c("img", {
                                          staticClass: "thumbImage",
                                          attrs: {
                                            src: _vm.form.commonImageResp
                                              .imageUrl,
                                            alt: "社群图",
                                          },
                                        }),
                                    _c("span", { staticClass: "modifyImage" }, [
                                      _vm._v(
                                        _vm._s(
                                          (_vm.form.commonImageResp.imageUrl
                                            ? "更换"
                                            : "上传") + "图片"
                                        )
                                      ),
                                    ]),
                                    _vm.form.commonImageResp.imageUrl
                                      ? _c("i", {
                                          staticClass: "el-icon-circle-close",
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.delImg()
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                ),
                                _c("div", { staticClass: "annotateGrey" }, [
                                  _vm._v(
                                    "建议上传图片宽度不小于750px，高度可根据图片大小拉伸，大小不超过1MB。"
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "备注", prop: "remark" } },
                            [
                              _c("el-input", {
                                staticClass: "inputWidth",
                                attrs: {
                                  disabled: !_vm.form.isUseCase,
                                  type: "textarea",
                                  placeholder: "备注长度介于 1 和 80 字符之间",
                                  maxlength: "80",
                                  autosize: { minRows: 1, maxRows: 5 },
                                },
                                on: { blur: _vm.submitForm },
                                model: {
                                  value: _vm.form.remark,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "remark", $$v)
                                  },
                                  expression: "form.remark",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c("TablePage", {
            ref: "tablePage",
            on: { handleEvent: _vm.handleEvent },
            model: {
              value: _vm.options,
              callback: function ($$v) {
                _vm.options = $$v
              },
              expression: "options",
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "素材库",
            visible: _vm.showDialog,
            width: "80%",
            "destroy-on-close": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
          },
        },
        [
          _c("Material", {
            attrs: { isDialog: true, isRadio: true },
            on: { accessUrlListCom: _vm.getAccessUrlList },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.imgUpdateSubmit },
                },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.imgUpdateCancel } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }