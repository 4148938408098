<template>
  <div class="wrap x-x">
    <div class="left">
      <cardTitleCom cardTitle="社群码预览" class="trpso">
        <template slot="cardContent">
          <div style="transform: scale(0.8)">
            <img
              src="https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2025-02-07/c9f68dd225e8428c942d1643c884eb25.png"   style='width: 375px;
    height: 70px;'/>
            <div class="leftBigImg"
              :style="{backgroundImage:`url(${form.commonImageResp.imageUrl || 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2025-02-13/e30769d2be7f420186e30b171fdc9622.png'})`}">
              <div style="padding-top:147.46px">
                <div class="x-f shop" :style="{opacity: form.caseType == 2 ? 1 : 0}">
                  <div class="city fontBold x-f" style="margin-right:10px">
                    <span style="margin-right:10px">深圳市</span>
                    <div>
                      <i class="el-icon-arrow-down" style="font-size:12px" />
                    </div>
                  </div>
                  <div class="omit1 shopName">
                    翻身路3号店
                  </div>
                </div>
                <div class="x-center">
                  <img style="width:195px;height:195px"
                    src="https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2025-01-20/16cba8ba7b11455790c2d845c87555f8.png" />
                </div>
              </div>
            </div>
          </div>
        </template>
      </cardTitleCom>
    </div>
    <div class="flex1">
      <div class="navSearch">
        <cardTitleCom cardTitle="社群码管理" class="navCard">
          <template slot="cardContent">
            <el-form :model="form" ref="form" label-width="120px">
              <el-form-item label="启动状态" prop="brandNo">
                <el-switch v-model="form.isUseCase" @change="submitForm">
                </el-switch>
              </el-form-item>
              <el-form-item label="活动模式" prop="brandNo">
                <el-radio-group v-model="form.caseType" :disabled='options.list.length > 0 || !form.isUseCase'
                  @change="submitForm">
                  <el-radio :label="1">统一设置</el-radio>
                  <el-radio :label="2">按门店</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="活动展示图片" prop="brandNo">
                <div>
                  <div class="choosedImageItem x-fc" @click="getOpenImg()">
                    <i v-if="!form.commonImageResp.imageUrl" class="el-icon-plus" style="font-size: 32px"></i>
                    <img v-else class="thumbImage" :src="form.commonImageResp.imageUrl" alt="社群图" />
                    <span class="modifyImage">{{`${form.commonImageResp.imageUrl ? '更换' : '上传'}图片`}}</span>
                    <i v-if="form.commonImageResp.imageUrl" class="el-icon-circle-close" @click.stop="delImg()"></i>
                  </div>
                  <div class="annotateGrey">建议上传图片宽度不小于750px，高度可根据图片大小拉伸，大小不超过1MB。</div>
                </div>
              </el-form-item>
              <el-form-item label="备注" prop="remark">
                <el-input :disabled='!form.isUseCase' @blur="submitForm" class="inputWidth" v-model="form.remark"
                  type="textarea" placeholder="备注长度介于 1 和 80 字符之间" maxlength="80"
                  :autosize="{ minRows: 1, maxRows: 5 }" />
              </el-form-item>
              <!-- <el-form-item> -->
              <!-- <el-button type="primary" size="small" @click="submitForm">保存</el-button> -->
              <!-- </el-form-item> -->
            </el-form>
          </template>
        </cardTitleCom>
      </div>
      <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent"> </TablePage>
    </div>
    <el-dialog v-dialogDrag title="素材库" :visible.sync="showDialog" width="80%" :destroy-on-close="true">
      <Material :isDialog="true" @accessUrlListCom="getAccessUrlList" :isRadio="true" />
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="imgUpdateSubmit">确 定</el-button>
        <el-button @click="imgUpdateCancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { setGetDetail, setUpdate, communityCodeList, communityCodeDelete } from "@/api/O2OMall/marketing/wxCommunityCode"; //接口api
export default {
  name: 'wxCommunityCode',
  components: {
    cardTitleCom: () => import("@/views/components/cardTitleCom"),
    TablePage: () => import("@/components/tablePage/index.vue"),
    Material: () => import("@/views/components/material/index.vue")
  },
  data() {
    return {
      accessUrlList: [],
      showDialog: false,
      form: {
        caseType: 1,
        commonImageResp: {},
      },
      options: {
        mutiSelect: true,
        loading: false,
        uuid: "b02e91ed-3aa1-ee94-737f-9cec2d898593",
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        title: "社群码",
        getListApi: communityCodeList,
        listNo: true, // 序号
        check: [], // 选中数据
        rowKey: "caseDetailId",
        //按钮
        buttons: [
          {
            click: "personalCode",
            label: "新增个人活码",
            icon: "el-icon-plus",
            type: "primary",
            noRule: true,
          },
          {
            click: "groupCode",
            label: "新增群活码",
            icon: "el-icon-plus",
            type: "primary",
            noRule: true,
          },
          {
            click: "handleDelete",
            label: "删除",
            type: "danger",
            icon: "el-icon-delete",
            alertText: '确认要删除选中的活码吗？',
          },
          {
            click: "refresh",
            right: true,
            label: "刷新",
            icon: "el-icon-refresh",
            type: "",
          },
        ],
        //表格
        columns: [
          {
            prop: "caseCodeName",
            label: "活码名称",
            type: "link",
            click: "routerLink",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "remark",
            label: "备注",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "detailCodeList",
            label: "活码",
            minWidth: 120,
            align: "center",
            type: 'image',
            formatter: (propValue, row) => {
              return propValue?.length > 0 ? propValue?.[0]?.commonImageResp?.imageUrl : '';
            },
          },
          {
            prop: "caseCodeCount",
            label: "二维码数量",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "caseCodeScanCount",
            label: "累计扫码人数",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "caseShopName",
            label: "门店",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "caseCodeType",
            label: "活码类型",
            minWidth: 120,
            align: "center",
            formatter: (propValue) => {
              return propValue == 1 ? "个人活码" : propValue == 2 ? '群活码' : '';
            },
          }
        ],
        list: [],
      },
    }
  },
  async created() {
    try {
      const res = await setGetDetail()
      this.form = res.data || { commonImageResp: {} };
      this.form.commonImageResp = this.form.commonImageResp ? this.form.commonImageResp : {}
    } catch (error) {
      this.form = { commonImageResp: {} };
    }
  },
  methods: {
    delImg() {
      if (!this.form.isUseCase) return this.$message.error('未开启活码功能，请先打开启动状态')
      this.$set(this.form, 'commonImageResp', {})
      this.submitForm()
    },
    getOpenImg() {
      if (!this.form.isUseCase) return this.$message.error('未开启活码功能，请先打开启动状态')
      this.showDialog = true
    },
    // 选择图片素材
    getAccessUrlList(value, row) {
      this.accessUrlList = row
    },
    // 选择图片弹框确定
    imgUpdateSubmit() {
      if (this.accessUrlList.length > 1) {
        return this.$message.error('图片信息只能单选')
      }
      this.$set(this.form.commonImageResp, 'imageUrl', this.accessUrlList[0]?.accessUrl)
      this.$set(this.form.commonImageResp, 'fileName', this.accessUrlList[0]?.fileName)
      this.$set(this.form.commonImageResp, 'fileSize', this.accessUrlList[0]?.fileSize)
      this.accessUrlList = []
      this.showDialog = false
      this.submitForm()
    },
    //上传图片弹窗取消
    imgUpdateCancel() {
      this.showDialog = false
      this.accessUrlList = []
    },
    async submitForm() {
      await setUpdate(this.form)
      this.$message.success("保存成功")
    },
    async handleEvent(type, row) {
      const selectData = this.options.check;
      switch (type) {
        case "routerLink":
          {
            this.$router.push({
              name: "wxCommunityCodeDetail",
              query: {
                type: "Update",
                caseType: this.form.caseType,
                caseDetailId: row.caseDetailId,
              },
            });
          }
          break;
        case "personalCode":
          {
            if (!this.form.isUseCase) return this.$message.error('未开启活码功能，请先打开启动状态')
            if (this.form?.caseType == 1 && this.options?.list?.length > 0) return this.$message.error("活动模式选择统一设置，活码不能添加多个")
            this.$router.push({
              name: "wxCommunityCodeDetail",
              query: {
                type: "add",
                caseCodeType: 1,
                caseType: this.form.caseType,
                caseId: this.form.caseId,
              }
            });
          }
          break;
        case "groupCode":
          {
            if (!this.form.isUseCase) return this.$message.error('未开启活码功能，请先打开启动状态')
            if (this.form?.caseType == 1 && this.options?.list?.length > 0) return this.$message.error("活动模式选择统一设置，活码不能添加多个")
            this.$router.push({
              name: "wxCommunityCodeDetail",
              query: {
                type: "add",
                caseCodeType: 2,
                caseType: this.form.caseType,
                caseId: this.form.caseId,
              }
            });
          }
          break;
        case "handleDelete":
          {
            const caseDetailsIds = selectData.map((item) => item.caseDetailId);
            await communityCodeDelete(caseDetailsIds);
            setTimeout(() => {
              this.$refs.tablePage.getList();
            }, 500);
            this.$message.success("删除成功");
          }
          break;
        default:
          break;
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.wrap {
  background-color: #eaeaea;
  .left {
    padding: 10px 0 0 10px;
    width: 400px;
    .trpso {
      height: calc(100vh - 105px);
      background-color: #fff;
    }
    .leftBigImg {
      width: 375px;
      height: 730px;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      .shop {
        background-color: #fff;
        padding: 11.27px;
        border-radius: 25px;
        margin: 0 auto;
        margin-bottom: 22px;
        width: 274px;
        font-size: 14px;
        .shopName {
          flex: 1;
        }
      }
    }
    img {
      display: block;
    }
  }
  .navSearch {
    padding: 10px 10px 0 10px;
    .navCard {
      margin-bottom: 0;
      ::v-deep .el-form-item {
        margin-bottom: 10px;
      }
      .choosedImageItem {
        position: relative;
        width: 80px;
        height: 80px;
        border: 1px solid #e5e5e5;
        text-align: center;
        margin-right: 10px;
        .thumbImage {
          min-height: 80px;
          box-sizing: border-box;
          vertical-align: bottom;
          max-height: 100%;
          max-width: 100%;
          height: auto;
          width: auto;
        }
        .modifyImage {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 80px;
          height: 20px;
          line-height: 20px;
          font-size: 12px;
          color: #fff;
          background: rgba(0, 0, 0, 0.5);
          cursor: pointer;
        }
        .el-icon-circle-close {
          position: absolute;
          top: 0;
          right: 0;
          color: rgba(0, 0, 0, 0.8);
          cursor: pointer;
          font-size: 22px;
        }
      }
    }
  }
}
::v-deep #table-page {
  height: calc(100vh - 400px) !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
</style>
